import styled from 'styled-components';

export const PageWrapper = styled.div`
  padding: 16px 16px 100px;
  box-sizing: 'border-box';
`;

export const ContentWrapper = styled.div`
  margin-left: max(calc(10% - 40px), 0px);
  margin-right: max(calc(10% - 40px), 0px);
`;
